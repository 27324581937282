export default class Fields {
  constructor(){

    $('body').on('focusin', '.form__base-input', function () {
      $(this).addClass('selected');
    });

    $('body').on('focusout', '.form__base-input', function () {
      $(this).removeClass('selected');
    });

    
    $('.base-select .dropdown-content.select-dropdown li.selected span').each(function() {
      if($(this).text().length > 0) {
        $(this).closest('.base-select').addClass('selected');
      }
    });

    $('.base-select .dropdown-content.select-dropdown li').click(function() {
      if($(this).children("span").first().text().length > 0) {
        $(this).closest('.base-select').addClass('selected');
      } else {
        $(this).closest('.base-select').removeClass('selected');
      }
    });

    $('select[multiple="multiple"]').change(function() {
        if(!$(this).val().length) {
            $(this).parent().parent().addClass("empty");
        } else {
            $(this).parent().parent().removeClass("empty");
        }
    });

    this.initRadioButtons()
  }

  initRadioButtons() {
    $('.radio input.radio_buttons:checked').parent().addClass('active');

    $('.radio input.radio_buttons').change(function(){
      $(this).closest("div.base-radio-buttons").find('label.active').removeClass('active');
      $(this).parent().addClass('active');
    })
  }
}
