import "../../../../node_modules/jquery-ui/themes/base/theme.css"
import "../../../../node_modules/jquery-ui/themes/base/datepicker.css"

var moment = require("moment")

$.fn.datetimepicker = require("../../../../node_modules/jquery-ui/ui/widgets/datepicker.js")

export default class BaseForm {
  constructor() {
    this.initToggleNestedForms()
    this.initInputHighlit()
    this.initSmsPopup()
    this.initOpenedNestedFormsOnLoad()
    this.initRateBlock()
    this.initDatePickers()
    this.fixInactiveLabels()
    this.initDraftLink()
  }

  initToggleNestedForms() {
    var toggle_content = undefined
    var trigger = $(".js-nested-form-toggle")
    trigger.each(function() {
      if (this.checked) {
        toggle_content = $(
          $(this).parents(".nested-form-toggle-block")[0]
        ).find(".nested-form-toggle-content")
        $(toggle_content[0]).css("display", "block")
        $(this)
          .closest(".js-margin-changing")
          .addClass("base-form__checkbox-wrapper-bottom")
      }
    })
    trigger.change(function() {
      if (this.checked) {
        toggle_content = $(
          $(this).parents(".nested-form-toggle-block")[0]
        ).find(".nested-form-toggle-content")
        $(toggle_content[0]).css("display", "block")
        $(this)
          .closest(".js-margin-changing")
          .addClass("base-form__checkbox-wrapper-bottom")
      } else {
        toggle_content = $(
          $(this).parents(".nested-form-toggle-block")[0]
        ).find(".nested-form-toggle-content")
        $(toggle_content[0]).css("display", "none")
        $(this)
          .closest(".js-margin-changing")
          .removeClass("base-form__checkbox-wrapper-bottom")
      }
    })

    $(".nested-form-toggle-select select").each(function() {
      if ($(this).val() == "new") {
        toggle_content = $(
          $(this).parents(".nested-form-toggle-block")[0]
        ).find(".nested-form-toggle-content")
        $(toggle_content[0]).css("display", "block")
        $(this)
          .closest(".js-margin-changing")
          .addClass("base-form__checkbox-wrapper-bottom")
      }
    })

    $(".nested-form-toggle-select select").change(function() {
      if ($(this).val() == "new") {
        toggle_content = $(
          $(this).parents(".nested-form-toggle-block")[0]
        ).find(".nested-form-toggle-content")
        $(toggle_content[0]).css("display", "block")
        $(this)
          .closest(".js-margin-changing")
          .addClass("base-form__checkbox-wrapper-bottom")
        $("#new_connection_object_flag").val(1)
      } else {
        toggle_content = $(
          $(this).parents(".nested-form-toggle-block")[0]
        ).find(".nested-form-toggle-content")
        $(toggle_content[0]).css("display", "none")
        $(this)
          .closest(".js-margin-changing")
          .removeClass("base-form__checkbox-wrapper-bottom")
        $("#new_connection_object_flag").val(0)
      }
    })
  }

  initOpenedNestedFormsOnLoad() {
    var toggle_content = undefined
    $(".js-nested-form-toggle").each(function() {
      if (this.checked) {
        toggle_content = $(
          $(this).parents(".nested-form-toggle-block")[0]
        ).find(".nested-form-toggle-content")
        $(toggle_content[0]).css("display", "block")
        $(this)
          .closest(".js-margin-changing")
          .addClass("base-form__checkbox-wrapper-bottom")
      }
    })
  }

  initInputHighlit() {
    $("body").on("focus", ".input-field.base-input input", function() {
      $(this)
        .parent()
        .addClass("focused")
    })

    $("body").on("blur", ".input-field.base-input input", function() {
      $(this)
        .parent()
        .removeClass("focused")
      if (
        ($(this).hasClass("js-index-input") ||
          $(this).hasClass("js-phone-input")) &&
        !$(this).val().length
      ) {
        $(this)
          .siblings("label")
          .removeClass("active")
      }
    })

    $(".js-phone-input").on("change", function() {
      $(".profile-form-confirmation-phone").css("display", "block")
      $(".js-phone-profile-confirmation").css("display", "block")
    })

    $(".js-phone-profile-confirmation").on("click", function() {
      var phone = $("#user_phone").val()
      $(".js-phone-profile-confirmation").css("display", "none")

      $.ajax({
        url: "/send_phone_confirmation_code",
        type: "GET",
        dataType: "json",
        data: { phone: phone },
        success: function(data, status, xhr) {
          console.log(data)
        },

        error: function(data) {
          console.log(data)
        }
      })
    })

    $("body").on("focus", ".input-field.base-textarea textarea", function() {
      $(this)
        .parent()
        .addClass("focused")
    })

    $("body").on("blur", ".input-field.base-textarea textarea", function() {
      $(this)
        .parent()
        .removeClass("focused")
    })

    $(".js-login-input").on("change", function() {
      var valueLogin = $(this).val()
      $(".js-login-input-for-sign-in").val(valueLogin)
    })
  }

  initSmsPopup() {
    $("body").on("focus", ".sms-popup__field input.text-input", function() {
      $(this)
        .siblings("label")
        .addClass("active")
    })

    $("body").on("blur", ".sms-popup__field input.text-input", function() {
      if (!$(this).val().length > 0) {
        $(this)
          .siblings("label")
          .removeClass("active")
      }
    })

    $(".sms-popup__field label").click(function() {
      $(this)
        .siblings("input.text-input")
        .focus()
    })
  }

  initRateBlock() {
    $(".base-form__rate-star:not(.disabled)").click(function() {
      var star_value = $(this).attr("data-value")

      var rate_block = $($(this).parents(".base-form__rate-block")[0])

      var stars = rate_block.find(".base-form__rate-star")

      stars.removeClass("active")
      stars.each(function() {
        var star_value_local = $(this).attr("data-value")

        if (star_value_local <= star_value) {
          $(this).addClass("active")
        }
      })

      rate_block.find("input").val(star_value)
    })
  }

  initDatePickers() {
    $.datepicker.regional.ru = {
      closeText: "Закрыть",
      prevText: "&#x3C;Пред",
      nextText: "След&#x3E;",
      currentText: "Сегодня",
      monthNames: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      monthNamesShort: [
        "Янв",
        "Фев",
        "Мар",
        "Апр",
        "Май",
        "Июн",
        "Июл",
        "Авг",
        "Сен",
        "Окт",
        "Ноя",
        "Дек"
      ],
      dayNames: [
        "воскресенье",
        "понедельник",
        "вторник",
        "среда",
        "четверг",
        "пятница",
        "суббота"
      ],
      dayNamesShort: ["вск", "пнд", "втр", "срд", "чтв", "птн", "сбт"],
      dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
      weekHeader: "Нед",
      dateFormat: "dd.mm.yy",
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: ""
    }
    var dateToday = new Date()

    $.datepicker.setDefaults($.datepicker.regional["ru"])
    $(".js-datepicker").each(function() {
      var maxDate = ""
      var minDate = ""

      if ($(this).attr("data-datepicker-type") == "not-more-date") {
        maxDate = dateToday
        minDate = ""
      }

      if ($(this).attr("data-datepicker-type") == "not-less-date") {
        maxDate = ""
        minDate = dateToday
      }

      if ($(this).attr("data-datepicker-type") == "not-more-less-date") {
        maxDate = "+4y"
        minDate = dateToday
      }

      if ($(this).attr("data-datepicker-type") == "not-less-2001-date") {
        maxDate = dateToday
        minDate = new Date("01.01.2001")
      }

      $(this).datepicker({
        dateFormat: "dd-mm-yy",
        maxDate: maxDate,
        minDate: minDate,
        defaultDate: dateToday
      })

      $(this).on("change", function(e) {
        if (moment($(this).val(), "DD-MM-YYYY").isValid()) {
          if (
            $(this).attr("data-datepicker-type") == "not-more-date" &&
            moment($(this).val(), "DD-MM-YYYY") >
              moment(dateToday, "DD-MM-YYYY")
          ) {
            $(this).datepicker("setDate", dateToday)
          }

          if (
            $(this).attr("data-datepicker-type") == "not-less-date" &&
            moment($(this).val(), "DD-MM-YYYY") <
              moment(dateToday, "DD-MM-YYYY")
          ) {
            $(this).datepicker("setDate", dateToday)
          }

          if (
            $(this).attr("data-datepicker-type") == "not-more-less-date" &&
            (moment($(this).val(), "DD-MM-YYYY") <
              moment(dateToday, "DD-MM-YYYY") ||
              moment($(this).val(), "DD-MM-YYYY") >
                moment(dateToday, "DD-MM-YYYY").add(4, "Y"))
          ) {
            $(this).datepicker("setDate", dateToday)
          }

          if (
            $(this).attr("data-datepicker-type") == "not-less-2001-date" &&
            (moment($(this).val(), "DD-MM-YYYY") <
              moment("01-01-2001", "DD-MM-YYYY") ||
              moment($(this).val(), "DD-MM-YYYY") >
                moment(dateToday, "DD-MM-YYYY"))
          ) {
            $(this).datepicker("setDate", dateToday)
          }
        } else {
          $(this).datepicker("setDate", dateToday)
        }
      })
    })
  }

  fixInactiveLabels() {
    $("div.input:has(label.string), div.input:has(label.password)").each(
      function(i, el) {
        let inputField = $(el).find("input")
        let label = $(el).find("label.string, label.password")
        let isWebkitAutofilled = false
        if (window.chrome) {
          isWebkitAutofilled = inputField.is(":-webkit-autofill")
        }
        if (
          (inputField.val() || isWebkitAutofilled) &&
          !label.hasClass("active")
        ) {
          label.addClass("active")
        }
      }
    )
  }

  initDraftLink() {
    $("form").on("keyup keypress", function(e) {
      if (e.which === 13) e.preventDefault()
    })
  }
}
