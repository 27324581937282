export default class Tabs {
  constructor(){

    $("body").on('click', '.base-tabs:not(.disabled) .base-tab-button', function (){
      $('.base-tabs').find('.base-tab_checked').removeClass('base-tab_checked');
      $(this).addClass('base-tab_checked');

      var index = $(this).attr('data-index');

      $('.base-tab-content').removeClass('is-visible');

      $(".base-tab-" + index).addClass("is-visible")

    })

  }

}