import Uppy from '@uppy/core'
import Russian from '@uppy/locales/lib/ru_RU'
import DashBoard from '@uppy/dashboard'
import cadesplugin from '../libs/cadesplugin_api'
// import FileInput from '@uppy/file-input'
// import Informer from '@uppy/informer'
// import ProgressBar from '@uppy/progress-bar'
import XHRUpload from '@uppy/xhr-upload'

var CADESCOM_CADES_BES = 1;
var CAPICOM_CURRENT_USER_STORE = 2;
var CAPICOM_MY_STORE = "My";
var CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;
// var CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME = 1;
// var CADESCOM_BASE64_TO_BINARY = 1;


function SignCreate(certSubjectName, dataToSign, hiddenInput, fileId) {

    return new Promise(function (resolve, reject) {
        cadesplugin.async_spawn(function* (args) {
            try {
                var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
                yield oStore.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE,
                    CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

                var CertificatesObj = yield oStore.Certificates;

                var checkedCert = $("#signCertSelect").val();
                var oCertificate = yield CertificatesObj.Item(parseInt(checkedCert));
                var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
                yield oSigner.propset_Certificate(oCertificate);

                var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                yield oSignedData.propset_Content(dataToSign);

                var sSignedMessage = yield oSignedData.SignCades(oSigner, CADESCOM_CADES_BES);

                var signData = jQuery.parseJSON($($(hiddenInput).next()).val());
                signData.push({id: fileId, sign: sSignedMessage});
                $($(hiddenInput).next()).val(JSON.stringify(signData));
                yield oStore.Close();

                args[2](sSignedMessage);
            } catch (e) {
                args[3]("Failed to create signature. Error: " + cadesplugin.getLastError(e));
            }
        }, certSubjectName, dataToSign, resolve, reject);
    });
}

// function Verify(sSignedMessage, dataToVerify) {
//     var oSignedData = cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
//     try {
//         oSignedData.ContentEncoding = CADESCOM_BASE64_TO_BINARY;
//         oSignedData.Content = dataToVerify;
//         // oSignedData.VerifyCades(sSignedMessage, CADESCOM_CADES_BES, true);
//     } catch (err) {
//         alert("Failed to verify signature. Error: " + cadesplugin.getLastError(err));
//         return false;
//     }
//
//     return true;
// }

function runSign(oFile, hiddenInput, fileId) {
    // Проверяем, работает ли File API
    if (window.FileReader) {
        // Браузер поддерживает File API.
    } else {
        alert('The File APIs are not fully supported in this browser.');
    }

    // oFile = document.getElementById("uploadFile").files[0];
    var oFReader = new FileReader();

    if (typeof (oFReader.readAsDataURL) != "function") {
        alert("Method readAsDataURL() is not supported in FileReader.");
        return;
    }

    oFReader.readAsDataURL(oFile);

    oFReader.onload = function (oFREvent) {
        var header = ";base64,";
        var sFileData = oFREvent.target.result;
        var sBase64Data = sFileData.substr(sFileData.indexOf(header) + header.length);

        var oCertName = 'ЛК КЭНК';
        var sCertName = oCertName.value; // Здесь следует заполнить SubjectName сертификата
        if ("" == sCertName) {
            alert("Введите имя сертификата (CN).");
            return;
        }
        SignCreate(sCertName, sBase64Data, hiddenInput, fileId);
    };
}

function fileUpload(hiddenInput) {
    const dashboardContainer = hiddenInput.dataset.containerClass;
    const uppy = Uppy({
        debug: true,
        //autoProceed: true,
        restrictions: {
            maxFileSize: 4000000,
            allowedFileTypes: ['.doc', '.docx', '.pdf', '.jpg', '.png', '.bmp']
        },
        locale: Russian
    })
        .use(DashBoard, {
            inline: true,
            target: dashboardContainer ? dashboardContainer : ".DashboardContainer",
            replaceTargetContent: true,
            showProgressDetails: true,
            height: 250,
            proudlyDisplayPoweredByUppy: false,
            showRemoveButtonAfterComplete: true,
            metaFields: [
                {id: 'name', name: 'Название'},
            ],
            browserBackButtonClose: true,
            locale: {
                strings: {
                    addMore: 'Прикрепить ещё'
                }
            }
        })
        .use(XHRUpload, {
            endpoint: '/upload',
            formData: true,
            fieldName: 'file'
        });

    uppy.on('complete', result => {
        var files = JSON.stringify(uppy.getFiles().map(file => (file.response.body)));
        hiddenInput.value = files;
    });

    var typeUser = $('body').attr('data-type-user');
    uppy.on('upload-success', (file, response) => {
        if(typeUser != 'individual'){
            console.log('check2');
            runSign(file.data, hiddenInput, response.body.id);
        }
    });

    uppy.on('file-removed', (file, reason) => {
        console.log(file, reason);
        uppy.removeFile(file.id);
        setTimeout(function () {
            if(uppy.getFiles().length > 0){
                hiddenInput.value = JSON.stringify(uppy.getFiles().map(file => (file.response ? file.response.body : '')));
            }
            else{
                hiddenInput.value = ''
            }
        }, 1000);
    })

    
}

export default fileUpload