export default class SideMenu {
  constructor(){
    var self = this;
    var tehConn = 'permanent-connection';
    var commercial = 'commercial';
    var other = 'other';

    $(".side-menu-options li").click(function () {
      $(".side-menu-options li").removeClass("list-item-selected");
      $(this).addClass("list-item-selected");
    });

    $(".side-menu__services-btn").click(function () {
      self.toggleMenu()
    });

    $(".side-menu__services-list--" + tehConn).click(function () {
      self.removeActive(this);
      self.openMenu();
      self.expandList(tehConn);
    });

    $(".side-menu__services-list--" + commercial).click(function () {
      self.removeActive(this);
      self.openMenu();
      self.expandList(commercial);
    });

    $(".side-menu__services-list--" + other).click(function () {
      self.removeActive(this);
      self.openMenu();
      self.expandList(other);
    });
  }

  openMenu() {
    if (!$('.side-menu').hasClass('open')) {
      this.toggleMenu();
    }
  }

  toggleMenu() {
    $(".side-menu").toggleClass("open");
    $(".menu-toggle").toggleClass("opened");
    $("header").toggleClass("side-menu-opened");
    $(".app-container").toggleClass("side-menu-opened");
  }

  removeActive(elem) {
    if(!elem.classList.contains('active')) {
      $('.side-menu__services-list').find('.active').removeClass("active");
      $(".services-wrap ul.active").removeClass('active');
      $(".service-group div.service-group__items-toggle-block").removeAttr('style');
    }
  }

  expandList(item) {
    $(".side-menu__services-list--" + item).toggleClass('active');
    $(".service-group--" + item).toggleClass('active');
    $(".service-group--" + item).find('.service-group__items-toggle-block').slideToggle();
  }

}