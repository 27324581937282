export default class CollapsibleBlock {
    constructor() {
        if($(".collapsible-block").length){
            $('.collapsible-block:not(.active)').on('click',function(e) {
                var target = $(event.target);
                if(target.closest(".collapsible-block").hasClass('active') && (!target.closest('.collapsible-block').length || target.closest('.status-history__header').length || target.closest('.request-preview__main ').length || target.closest('.counter-item-preview__header').length)){
                    $(this).closest(".collapsible-block").removeClass("active");
                } else {
                    $(".collapsible-block.active").removeClass("active");
                    $(this).closest(".collapsible-block").addClass("active");
                }          
            });
            // $(document).click(function(event) {
            //     var target = $(event.target);
            //     console.log(!target.closest('.collapsible-block').length, $(target).closest('.base-form').length);
            //     if(!target.closest('.collapsible-block').length  || $(target).closest('.base-form').length) {
            //         $('.collapsible-block.active').removeClass("active");
            //     }
            // });
        }
    }
}