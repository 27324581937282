export default class Dropdown {
    constructor() {
        $(".dropdown-elem").click(function () {
            $(".dropdown-cont").not($(this).find(".dropdown-cont")).slideUp("slow");
            $(".dropdown-elem").not($(this)).removeClass('dropdown-elem--active');

            $(this).find(".dropdown-cont").slideToggle("slow");
            $(this).toggleClass('dropdown-elem--active')
        });

        $(document).click(function (event) {
            if (!$(event.target).closest('.dropdown-elem').length) {
                $(".dropdown-cont").slideUp("slow");
                $(".dropdown-elem").removeClass('dropdown-elem--active');
            }
        });


        $('.service-group__category').click(function () {
            var wrapper = $(this).parents('.service-group');

            if (!this.parentNode.classList.contains('active')) {
                $(".services-wrap ul.active").removeClass('active');
                $(".service-group div.service-group__items-toggle-block").removeAttr('style');
            }

            $($(wrapper)[0]).toggleClass('active');
            $($(wrapper)[0]).find('.service-group__items-toggle-block').slideToggle();

            if (this.parentNode.classList.contains('active')) {
                $('.side-menu__services-list').find('.active').toggleClass("active");
            }

            var cls = this.parentNode.classList[1];

            switch (cls) {
                case 'service-group--permanent-connection':
                    $(".side-menu__services-list--permanent-connection").toggleClass('active');
                    break;
                case 'service-group--commercial':
                    $(".side-menu__services-list--commercial").toggleClass('active');
                    break;
                case 'service-group--other':
                    $(".side-menu__services-list--other").toggleClass('active');
                    break;
                default:
                    return;
            }
        })
    }
}